window.addEventListener("load", function(){
    window.wpcc.init({
        "corners":"normal",
        "colors": {
            "popup": {
                "background":"#e2f7d4",
                "text":"#000000",
                "border":"#7fc25e"
            },
            "button": {
                "background":"#7fc25e",
                "text":"#ffffff"
            }
        },
        "position":"bottom",
        "border":"thin",
        "content": {
            "href": "/oldalrol",
            "message":"Az oldal sütiket (cookie) használ a megfelelő működése érdekében!",
            "link":"Bővebben...",
            "button":"Értettem"
        }
    })
});


