
    // Ajax Töltés -- GET
AT = function(hova, mit) {
    $.ajax({
        url: mit,
        type: 'GET',
        success: function(re) {
            $("#" + hova).html(re);
        }
    });
}

kedvencekSarokRF = function() { AT('kedvencek-betekinto','/kedvencek'); }
hasonlitoSarokRF = function() { AT('hasonlito-betekinto','/hasonlitas'); }

nevnapKereso = function() {
    var keresendo = $("#keresendo-nevnap").val();
    $(".bloki-nevnapok *").removeClass("kiemelt"); $("#keresendo-nevnap").removeClass("no-talalat");

    if (keresendo.length > 2) {    
        var leltElement = $(".bloki-nevnapok td:contains('"+keresendo+"')");
        if ( leltElement.attr('id') ) {
            var leltPozi = leltElement.offset();
            $(document).scrollTop( leltPozi.top - 136 );
            leltElement.addClass("kiemelt");
        } else { $("#keresendo-nevnap").addClass("no-talalat"); }
    } else if (keresendo.length > 0 && keresendo.length < 3) { 
        alert("A névnap kereséséhez legalább 3 karakter szükséges!"); 
    } else {  }
}

$(document).on("scroll", function(){
    if ( $(document).scrollTop() > 136 ) {
        $(".felsav-fo-tarto").addClass("szukitett");
    } else {
        $(".felsav-fo-tarto").removeClass("szukitett");
    }
});



